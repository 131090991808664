import { Field, Form, useFormik, Formik} from 'formik';
import { DescriptionField, InputField } from './elements';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import * as Yup from 'yup';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function ContactModal({setToggleModal}) {
    const [open, setOpen] = useState(false)

    const schema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        institution: Yup.string().required('Required'),
        email: Yup.string().required('Required').email('Invalid email address'),
        message: Yup.string().required('Required'),
    })
  
    const formik = useFormik({
      initialValues: {
        firstName: null,
        lastName: null,
        institution: null,
        email: null,
        message: null,
      },
      validationSchema: schema,
      validateOnMount: true,
    })

    function sendEmail(e) {
      e.preventDefault();
      emailjs.send('service_uq1sl4n', 'template_sys9jl8', formik.values, 'Cmj9VpwpnB_UROGkx')
    //   setToggleModal()
      setOpen(true);
    }

    function closeModals() {
        setOpen(false);
        setToggleModal();
    }
  
    return (
        <>
        { open && (
            <Modal
              className="modal is-active is-small"
              open={open}
              onClose={setToggleModal}
            >
              <Box sx={{ ...style, width: 400 }}>
                <div className="level">
                  <Typography variant="h5" className="m-5">Email sent!</Typography>
                  <button
                    style={{ marginLeft: 'auto'}}
                    className="delete"
                    onClick={setToggleModal}
                    aria-label="close"
                    ></button>
                </div>
                  <p id="parent-modal-description" className="m-2">
                  Thank you for your inquiry! <br/><br /> Someone from the VivoSurgery team will be reaching out to you shortly.
                  </p>
                  <Button
                    variant="outlined"
                    sx={{ color: '#26b0c5', marginTop: '1em' }}
                    onClick={() => closeModals()}
                  >
                    Close
                  </Button>
              </Box>
            </Modal>
        )
        }
        <Modal
          id="contact-us"
          className="modal is-active"
          open
          onClose={setToggleModal}
        >
        {/* <div className="modal-background"></div> */}
        <div className="modal-card">
            <header className="modal-card-head">
                <Typography variant="h4">Contact Us</Typography>
            {/* <p className="modal-card-title has-text-light">Contact Us</p> */}
            <button
              style={{ marginLeft: 'auto'}}
              className="delete"
              onClick={setToggleModal}
              aria-label="close"
            ></button>
            </header>
            <section className="modal-card-body">
            <Formik
            validationSchema={schema}
            validateOnChange
            validateOnMount
            onSubmit={(values) => console.log('SUBMITTING VALUES: ', values)}
            >
              <Form onSubmit={formik.handleSubmit}>
                <div className="columns is-multipline has-text-dark">
                <div className="column">
                    <label htmlFor="firstName">First Name</label>
                    <Field
                    component={InputField}
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    />
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                    component={InputField}
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    />
                    <label htmlFor="institution">Business/Institution</label>
                    <Field
                    component={InputField}
                    id="institution"
                    name="institution"
                    type="text"
                    placeholder="Business/Institution"
                    onChange={formik.handleChange}
                    value={formik.values.institution}
                    />
                    <label htmlFor="email">Email Address</label>
                    <Field
                    component={InputField}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    />
                    <label htmlFor="message">Message</label>
                    <Field
                    component={DescriptionField}
                    id="message"
                    name="message"
                    type="text"
                    placeholder="Message"
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    />
                </div>
                </div>
              </Form>
            </Formik>
            </section>
            <footer className="modal-card-foot">
            <Button
              variant="contained"
              style={{ background: '#26b0c5' }}
              onClick={sendEmail}
              disabled={!!!formik.isValid}
            >
              Submit
            </Button>
            {/* <button
                className="button has-text-light"
                style={{backgroundColor: '#26b0c5'}}
                onClick={sendEmail}
            >
                Submit
            </button> */}
            <Button
                variant="outlined"
                style={{ marginLeft: 'auto', background: '#FFFFFF', color: 'darkgray' }}
                onClick={setToggleModal}
            >
                Cancel
            </Button>
            </footer>
        </div>
        </Modal>
        </>
    )
}

export default ContactModal;