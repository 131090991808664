import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class NavItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { history, goTo, onClick } = this.props;
    if (onClick) {
      onClick();
    } else if (goTo) {
      history.push(goTo);
    }
  }

  render() {
    const {
      'aria-label': ariaLabel, children,
    } = this.props;

    return (
      <a
        aria-label={ariaLabel}
        className="navbar-item"
        onClick={this.handleClick}
        role="navigation"
      >
        {children}
      </a>
    );
  }
}

NavItem.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  goTo: PropTypes.string,
  onClick: PropTypes.func,
};

NavItem.defaultProps = {
  onClick: undefined,
  goTo: undefined,
};

export default withRouter(NavItem);
