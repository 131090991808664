// import Logo from './images/valueProp.jpeg';
import ProofOne from './images/Vivo_DrAK.jpg'
import ProofTwo from './images/Vivo_DrVL.jpg'
import ProofThree from './images/Vivo_GJ_New.jpg'
import ProofFour from './images/Vivo_DrOB.jpg'
import ProofFive   from './images/Vivo_HM.jpg'
import './style.scss'
// import NavItem from './NavItem';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, Button, Card, CardContent, CardMedia, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import socialProofImg from './images/Interactive _Vivo_Mockup.jpg';

function SocialProof() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down("lg"))
  
    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    return (
        <>
        <section className="hero is-medium" style={{ background: 'white' }}>
          <div className="hero-body">
            {isExtraSmall && (
              <>
              <Button
                    variant="contained"
                    width="fit-content"
                    disabled
                    style={{
                      background: "#26b0c5",
                      color: 'white',
                      width: 'fit-content',
                      marginTop: '17%',
                      marginBottom: '11%',
                      position: 'relative',
                      left: '0'
                    }}
                  >
                  What we do
                  </Button>
                <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="h4" style={{ textAlign: 'left' }}>
                  Remote surgical shadowing platform
                </Typography>
              </CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                <Typography
                  style={{
                    textAlign: 'left',
                    marginRight: isExtraSmall? '2em': '0',
                    marginLeft: isExtraSmall? '1em': '0'
                    }}
                >
                  We’re using cloud technology
                  to make learning advanced surgical procedures from live
                  Operating theatres scalable. Our goal is to ensure more
                  patients can benefit from cutting-edge treatments by
                  providing surgical teams with earlier opportunities to
                  adopt them. We envision improved patient outcomes
                  through sustainable resourcing of surgeon expertise.
                </Typography>
              </Box>
              <CardMedia
                component="img"
                // sx={{ width: 600 }}
                style={{
                  marginTop: '2em',
                  marginLeft: '1em',
                  width: '90%',
                  justifyContent: 'center',
                  display: 'flex',
                  border: '1px solid darkgray'
                }}
                image={socialProofImg}
                alt="social-proof-img"
              />
              </>
            )}
            { !!!isExtraSmall && (
            <Card variant='' sx={{ display: 'flex', marginTop: '5%', marginLeft: '13.5%', marginRight: '13.5%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '5em', justifyContent: 'flex-start'}}>
                  <Button
                    variant="contained"
                    width="fit-content"
                    disabled
                    style={{
                      background: "#26b0c5",
                      color: 'white',
                      width: 'fit-content',
                      marginTop: '11%',
                      marginBottom: '11%',
                    }}
                  >
                    What we do
                   </Button>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="h4" style={{ textAlign: 'left' }}>
                    Remote surgical shadowing platform
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                  <Typography style={{ textAlign: 'left' }}>
                    We’re using cloud technology
                    to make learning advanced surgical procedures from live
                    Operating theatres scalable. Our goal is to ensure more
                    patients can benefit from cutting-edge treatments by
                    providing surgical teams with earlier opportunities to
                    adopt them. We envision improved patient outcomes
                    through sustainable resourcing of surgeon expertise.
                  </Typography>
                </Box>
              </Box>
              <CardMedia
                component="img"
                // sx={{ width: 600 }}
                style={{ marginLeft: '3em', width: '50%', justifyContent: 'center', display: 'flex', border: '1px solid darkgray' }}
                image={socialProofImg}
                alt="social-proof-img"
              />
            </Card>
            )}
          </div>
        </section>
        <section className="hero is-medium mb-6" style={{background: 'white'}}>
          <div className="hero-body">
            {isExtraSmall && (
              <Button
              variant="contained"
              width="fit-content"
              disabled
              style={{
                background: "#26b0c5",
                color: 'white',
                width: 'fit-content',
                marginBottom: '5%',
              }}
            >
              Testimonials
            </Button>
          )}
              <div
                  className={`has-text-centered ${isMobile ? 'social-proof-container-mobile' : 'social-proof-container'}`}
              >
              <Carousel infiniteLoop autoPlay showStatus={false} >
                  <div className="horizontal-card-container mt-5">
                      <img className="quote" src={ProofOne} />
                  </div>
                  <div className="horizontal-card-container mt-5">
                      <img className="quote" src={ProofTwo} />
                  </div>
                  <div className="horizontal-card-container mt-5">
                      <img className="quote" src={ProofThree} />
                  </div>
                  <div className="horizontal-card-container mt-5">
                      <img className="quote" src={ProofFour} />
                  </div>
                  <div className="horizontal-card-container mt-5">
                      <img className="quote" src={ProofFive} />
                  </div>
              </Carousel>
              </div>
            </div>
        </section>
        </>
    )
}

export default SocialProof;