import { useState } from 'react';
import Logo from './images/VIVO_Logo_312x96.png';
import ContactModal from './Modal';
import './style.scss'
import { Typography } from '@mui/material';
import iBioMedLogo from './images/ibiomedLogo.png';
import hhsDriveLogo from './images/hhsDriveGrantLogo.png';

function Footer() {
    const [modal, setModal] = useState(false);
  
    function toggleModal() {
      console.log('MODAL', modal)
      setModal(!modal);
    };
  
    return (
    <footer className="footer" style={{background: "black"}}>
        {
          modal && (
            <ContactModal setToggleModal={toggleModal}/>
          )
        }
        <div style={{textAlign: 'center'}}>
          <Typography color="white">
              Trusted by
          </Typography>
          <div className="mb-6">
            <img
              alt='ibiomedlogo'
              src={iBioMedLogo}
              style={{
                  width: '300px',
                  paddingBottom: '1em',
            }}
            />
            <img
              alt='hhsdrivelogo'
              src={hhsDriveLogo}
              style={{
                  width: '300px',
                  marginLeft: '1em',
                  paddingTop: '1em',
            }}
            />
          </div>
        </div>
        <div className="columns has-text-white is-mobile">
            <div className="column has-text-centered">

            <img
                    src={Logo}
                    alt="Vivosurgery Logo"
                    width="96"
                    height="312"
                />
            <h4 className="bd-footer-title 
                        has-text-weight-medium
                        has-text-cenetered">
                VivoSurgery
            </h4>
            <div className="content has-text-centered">
                <p>Copyright © 2023. All rights reserved.</p>
            </div>
            </div>
        
            <div className="column">
            <br />
            <a href="./privacy">
                <h4 className="has-text-white has-text-weight-medium has-text-justify"
                >
                    Privacy Policy
                </h4>
            </a>
            <a href="./terms">
                <h4 className="has-text-white has-text-weight-medium has-text-justify"
                >
                    Terms & Conditions
                </h4>
            </a>
            </div>

            <div className="column">
            <h4 className="bd-footer-title
                        has-text-weight-medium
                        has-text-justify">
                Contact us
            </h4>
            <p className="bd-footer-link">
                <a onClick={() => toggleModal()}>
                    <span className="icon-text">
                        <span>Email</span>
                    </span>
                    </a>
                <br />
            </p>
            <p className="bd-footer-link">
                <a href="https://ca.linkedin.com/company/vivo-surgery" target="_blank">
                    <span className="icon-text">
                        <i className="fa-brands fa-linkedin pt-1 mr-1"/>
                        <span>LinkedIn</span>
                    </span>
                    </a>
                <br />
            </p>
            </div>
        </div>
    </footer>
    )
}

export default Footer;