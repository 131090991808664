// import Logo from './images/valueProp.jpeg';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Navbar from './Navbar';
import './style.scss'
import background from './images/valueProp.jpeg'
// import NavItem from './NavItem';

function ValueProposition() {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <section style={{minHeight:  isExtraSmall ? '50em': '53em', background: 'linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 10%)'}}>
      <div 
        style={{ overflow: 'hidden' }}
      >
          <img
            alt='backgroundProposition'
            src={background}
            style={{
              objectFit: 'cover',
              filter: 'brightness(0.5)',
              position: 'absolute',
              zIndex: '-1',
              left: '0',
              overflow: 'hidden',
              width: '100%',
              height: '850px',
          }}
          />
          <section className="hero is-medium is-mobile">
            <Navbar />
            <div className="hero-body">
                <div style={{marginTop: "4.5em", width: "100%", textAlign: 'center'}}>
                    <Typography
                      style={{ color: 'white', fontWeight: '500' }}
                      sx={{
                        typography: { sm: "h2", xs: "h3", md: "h1", lg: "h1", xl: "h1"}
                      }}
                    >
                        Remotely access live surgical expertise.
                    </Typography>
                <Button
                  variant="outlined"
                  size={isExtraSmall ? "medium" : "large"}
                  style={{
                    background: '#26b0c5',
                    border: '#26b0c5',
                      marginTop: '3em',
                      marginRight: '1em',
                      color: 'white',
                  }}
                  data-target="contact-us-"
                  href="https://calendly.com/phillip_mohabir/30min?month=2023-11"
                  target="_blank"
                >
                  Book a meeting
                 </Button>
                <Button
                    variant="outlined"
                    size={isExtraSmall ? "medium" : "large"}
                    style={{
                      background: 'white',
                      border: 'white',
                      marginTop: '3em',
                      borderColor: '#26b0c5',
                      color: '#26b0c5',
                      // textTransform: 'none',
                    }}
                    data-target="log-in"
                    href="https://vivosurgery.io/landing/login"
                >
                  Go to Dashboard
                </Button>
              </div>
              </div>
          </section>
      </div>
    </section>
  )
}

export default ValueProposition;