import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';

const InputField = ({
  field,
  form: {
    touched,
    errors,
    isSubmitting,
  },
  label,
  iconLeft,
  iconRight,
  ...props
}) => (
  <div className="field">
    <label>
      {label}
      <div
        className={classnames('control', {
          'has-icons-left': iconLeft,
          'has-icons-right': iconRight,
        })}
      >
        <input
          className={classnames('input', {
            'is-danger': touched[field.name] && errors[field.name],
          })}
          disabled={isSubmitting}
          {...field}
          {...props}
        />
        {
          iconLeft && (
            <span className="icon is-small is-left">
              <i className={iconLeft} />
            </span>
          )
        }
        {
          iconRight && (
            <span className="icon is-small is-right">
              <i className={iconRight} />
            </span>
          )
        }
      </div>
    </label>
    <ErrorMessage className="help is-danger" component="p" name={field.name} />
  </div>
);

InputField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  label: PropTypes.string,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    touched: PropTypes.object.isRequired,
  }).isRequired,
};

InputField.defaultProps = {
  label: '',
  iconLeft: undefined,
  iconRight: undefined,
};

const DescriptionField = ({
  field,
  form: {
    touched,
    errors,
    isSubmitting,
  },
  label,
  iconLeft,
  iconRight,
  ...props
}) => (
  <div className="field">
    <label>
      {label}
      <div
        className={classnames('control', {
          'has-icons-left': iconLeft,
          'has-icons-right': iconRight,
        })}
      >
        <textarea
          type="textarea"
          className={classnames('textarea', {
            'is-danger': touched[field.name] && errors[field.name],
          })}
          disabled={isSubmitting}
          style={{ height: '10em' }}
          {...field}
          {...props}
        />
        {
          iconLeft && (
            <span className="icon is-small is-left">
              <i className={iconLeft} />
            </span>
          )
        }
        {
          iconRight && (
            <span className="icon is-small is-right">
              <i className={iconRight} />
            </span>
          )
        }
      </div>
    </label>
    <ErrorMessage className="help is-danger" component="p" name={field.name} />
  </div>
);

DescriptionField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  label: PropTypes.string,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    touched: PropTypes.object.isRequired,
  }).isRequired,
};

DescriptionField.defaultProps = {
  label: '',
  iconLeft: undefined,
  iconRight: undefined,
};

const SelectField = ({
  children,
  field,
  form: {
    touched,
    errors,
    isSubmitting,
  },
  label,
  placeholder,
  ...props
}) => (
  <div className="field">
    <label>
      {label}
      {label && <br />}
      <div
        className={classnames('select', {
          'is-danger': touched[field.name] && errors[field.name],
        })}
        style={{ width: '100%' }}
      >
        <select
          disabled={isSubmitting}
          style={{ width: '100%' }}
          {...field}
          {...props}
        >
          <option value="" disabled hidden>{placeholder}</option>
          {children}
        </select>
      </div>
    </label>
    <ErrorMessage className="help is-danger" component="p" name={field.name} />
  </div>
);

SelectField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([
      PropTypes.node,
    ]),
  ]),
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

SelectField.defaultProps = {
  children: undefined,
  label: '',
  placeholder: 'Select an Option',
};

const CheckField = ({
  field,
  form: {
    touched,
    errors,
    isSubmitting,
  },
  label,
  ...props
}) => (
  <div className="field">
    <label className="checkbox">
      <input
        className={classnames('checkbox', {
          'is-danger': touched[field.name] && errors[field.name],
        })}
        disabled={isSubmitting}
        type="checkbox"
        style={{ marginRight: '0.5rem' }}
        {...field}
        {...props}
      />
      {label}
      <ErrorMessage className="help is-danger" component="p" name={field.name} />
    </label>
  </div>
);

CheckField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

CheckField.defaultProps = {
  label: '',
};

const TextAreaField = ({
  field,
  form: {
    touched,
    errors,
    isSubmitting,
  },
  label,
  ...props
}) => (
  <div className="field">
    <label>
      {label}
      <textarea
        className={classnames('textarea', {
          'is-danger': touched[field.name] && errors[field.name],
        })}
        disabled={isSubmitting}
        {...props}
        {...field}
      />
    </label>
    <ErrorMessage className="help is-danger" component="p" name={field.name} />
  </div>
);

TextAreaField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

TextAreaField.defaultProps = {
  label: '',
};

export {
  CheckField,
  InputField,
  SelectField,
  TextAreaField,
  DescriptionField,
};
