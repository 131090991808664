import { useEffect } from 'react';
import { useState } from 'react';
import Logo from '../images/VIVO_Logo_312x96.png';
import ContactModal from '../Modal';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import NavItem from './NavItem';

function Navbar() {
  const [modal, setModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
      window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1200;
          if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
  }, [isMobile]);


  function toggleModal() {
    setModal(!modal);
  };

  return (
    <>
    {
      modal && (
        <ContactModal setToggleModal={toggleModal}/>
      )
    }
    <AppBar id="header-navbar" position="relative" sx={{ bgcolor: 'rgba(0,0,0,0.3)', height: '80px' }}>
      <Toolbar style={{ height: '55px' }}>
          <a
            aria-label="logo"
            href="."
            role="navigation"
            style={{ height: '55px' }}
          >
            <img
              src={Logo}
              alt="Vivosurgery Logo"
              width="155"
              height="60"
              style={{ marginTop: '0.5em'}}
            />
          </a>
        <div style={{ marginLeft: 'auto', marginTop: '1em' }}>
          <Button
            variant="contained"
            style={{ background: "transparent", marginRight: "1em" }}
            href="https://vivosurgery.io/landing/login"
          >
            Log in
          </Button>
          <Button
            variant="contained"
            style={{ background: '#26b0c5' }}
            data-target="contact-us-"
            onClick={() => toggleModal()}
          >
              Contact Us
          </Button>
          
        </div>
      </Toolbar>
    </AppBar>
      {/* <nav
        className="level is-mobile"
        role="navigation"
        aria-label="main-nav"
        style={{background: '#0a0a0a', marginBottom: '0'}}
      >
        {
          modal && (
            <Modal setToggleModal={toggleModal}/>
          )
        }
          <div className={`navbar-brand ${isMobile ? "mobile-nav-bar" : "desktop-nav-bar"}`}>
          <nav className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <a
                    aria-label="logo"
                    className="navbar-item"
                    href="."
                    role="navigation"
                    // style={{marginLeft: "300%"}}
                >
                  <img
                      src={Logo}
                      alt="Vivosurgery Logo"
                      width="96"
                      height="312"
                  />
                  </a>
              </div>
            </div>
            <div class="navbar-end" style={{marginRight: "20%"}}>
            <div className="level-right">
              <div class="navbar-item">
              <div className="level-item">
                <div class="buttons">
                  <a class="button is-black is-size-7" href="https://vivosurgery.io/landing/login">
                    Log in
                  </a>
                </div>
              </div>
              <div className="level-item">
                  <a
                    class="button is-primary is-size-7 has-text-dark"
                    style={{borderRadius: "25Px", background: '#26b0c5', marginRight: '1em'}}
                    data-target="contact-us-"
                    onClick={() => toggleModal()}
                  >
                    <strong>Contact Us</strong>
                  </a>
              </div>
            </div>
          </nav>
      </nav> */}
      </>
    )
}

export default Navbar;